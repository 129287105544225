import React from "react"
import PostList from "./postList"
import styles from "../styles/featuredCollection.module.css"
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { Link } from "gatsby"   
import ProminentButton from "./prominentButton"

const FeaturedCollection = ({data, title, url}) => {
    return (
        <div className={styles.collectionWrapper}>
            <div className={styles.headerWrapper}>
                <h2 className={styles.collectionHeader}>{title}</h2>
            </div>
            <PostList data={data} count={4}/>
            <Link to={url}>
                <ProminentButton text={'See More'}/>
            </Link>
        </div>
    )
}

export default FeaturedCollection