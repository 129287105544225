import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styles from '../styles/featuredBanner.module.css'
import {isMobile} from 'react-device-detect';
import BackgroundImage from 'gatsby-background-image'

const FeaturedBanner = ({url, title, image}) => {
        return (
            <div className={styles.bannerWrapper}>
                <Link to={url} style={{width: '100%', height: '100%'}}>
                    <BackgroundImage fluid={image.childImageSharp.fluid} className={styles.bannerBackground}>
                            <div className={styles.bannerHeaderMobileContainer}>
                            </div>
                    </BackgroundImage>
                    <div className={styles.BHMText}>
                        <span className={styles.bannerHeaderMobile}>{title}</span>
                    </div>
                </Link>
            </div>
        )
}

export default FeaturedBanner

