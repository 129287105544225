import React from "react"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import PostList from '../components/postList'
import FeaturedCollection from '../components/featuredCollection'
import FeaturedBanner from '../components/featuredBanner'
import ProminentButton from '../components/prominentButton'
import { Link } from "gatsby"
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import styles from '../styles/index.module.css'


const IndexPage = ({data, location}) => {
  return(
    <div className={styles.pageWrapper}>
    <SEO title="Tasty Appetite" canonicalPath={location.pathname}/>
    {data.allStrapiFeaturedCollections.edges.map((featuredCollection) => {
      if(featuredCollection.node.type == 'list'){
        return <FeaturedCollection data={featuredCollection.node.fields.posts} title={featuredCollection.node.Title} url={featuredCollection.node.fields.url}/>
      } else if(featuredCollection.node.type == 'banner'){
          return <FeaturedBanner 
                    url={featuredCollection.node.fields.url} 
                    title={featuredCollection.node.Title}
                    image={featuredCollection.node.featureImage}
                  />
      }
    })}
    <h2 className={styles.collectionHeader}>Recently Added</h2>
    <PostList data={data.allStrapiPosts.edges} />
    <Link to="/page/2">
        <ProminentButton text={'See More'}/>
    </Link>
    </div>
  )
}

export const query = graphql`
  query postListQuery {
    allStrapiFeaturedCollections(sort: {fields: priority}) {
      edges {
        node {
          Title
          type
          priority
          featureImage {
            childImageSharp {
              fluid{
                src
                srcSet
                presentationWidth
                presentationHeight
                base64
                aspectRatio
              }
            }
          }
          fields {
            url
            posts {
              id
              title
              websiteURL
              youtubeURL
              featureImage {
                childImageSharp {
                  fluid(cropFocus: ENTROPY){
                    src
                    srcSet
                    presentationWidth
                    presentationHeight
                    base64
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
    allStrapiPosts(
      sort: { fields: [created_at], order: DESC }
      limit: 8
    ) {
      edges {
        node {
          id
          title
          description
          websiteURL
          created_at
          youtubeURL
          recipe_category {
            name
          }
          recipe_cuisine {
            name
          }
          featureImage {
            childImageSharp {
              fluid(cropFocus: ENTROPY){
                src
                srcSet
                presentationWidth
                presentationHeight
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
